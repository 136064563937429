import { createApp } from 'vue'
import App from './App.vue'
import './index.css'
import { createRouter, createWebHashHistory } from 'vue-router'
import Dashboard from './components/Dashboard.vue'
import About from './components/About.vue'

const router = createRouter({
	history: createWebHashHistory(),
	routes: [
		{ path: '/', component: Dashboard },
		{ path: '/about', component: About },
	],
})

createApp(App).use(router).mount('#app')

<template>
  <div
    class="min-h-[calc(100vh-5rem)] sm:min-h-screen bg-cover"
    :style="{ backgroundImage: `url(${require('../assets/test.svg')}` }"
  >
    <Disclosure as="nav" class="bg-white border-b" v-slot="{ open }">
      <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="flex justify-between h-16">
          <div class="flex">
            <div class="flex items-center flex-shrink-0">
              <RouterLink to="/" class="block w-auto text-3xl cursor-pointer">🦊</RouterLink>
            </div>
            <div class="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
              <router-link
                v-for="item in navigation"
                :key="item.name"
                :to="item.href"
                :class="[
                  item.current
                    ? 'border-indigo-500 text-gray-900'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium',
                ]"
                :aria-current="item.current ? 'page' : undefined"
                >{{ item.name }}</router-link
              >
            </div>
          </div>
          <div class="flex items-center -mr-2 sm:hidden">
            <a
              class="flex items-center px-2 py-2 mr-2 text-sm text-gray-600 border-gray-300 border-dashed rounded-md"
              style="border-width: 1px"
              href="https://www.buymeacoffee.com/hellofabien"
              target="_blank"
              ><img class="w-5 h-5" :src="require('../assets/gift.svg')" />
              <span class="ml-2">Soutenir</span></a
            >
            <!-- Mobile menu button -->
            <DisclosureButton
              class="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <span class="sr-only">Open main menu</span>
              <Bars3Icon
                v-if="!open"
                class="block w-6 h-6"
                aria-hidden="true"
              />
              <XMarkIcon v-else class="block w-6 h-6" aria-hidden="true" />
            </DisclosureButton>
          </div>
          <div class="items-center hidden sm:flex">
            <a
              class="flex items-center h-10 px-3 mr-2 text-sm text-gray-600 border-gray-300 border-dashed rounded-md"
              style="border-width: 1px"
              href="https://www.buymeacoffee.com/hellofabien"
              target="_blank"
              ><img class="w-5 h-5" :src="require('../assets/gift.svg')" />
              <span class="ml-2">Soutenir</span></a
            >
          </div>
        </div>
      </div>

      <DisclosurePanel class="sm:hidden">
        <div class="pt-2 pb-3 space-y-1">
          <RouterLink
            v-for="item in navigation"
            :key="item.name"
            :to="item.href"
            :class="[
              item.current
                ? 'bg-indigo-50 border-indigo-500 text-indigo-700'
                : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800',
              'block pl-3 pr-4 py-2 border-l-4 text-base font-medium cursor-pointer',
            ]"
            :aria-current="item.current ? 'page' : undefined"
            >{{ item.name }}</RouterLink
          >
        </div>
      </DisclosurePanel>
    </Disclosure>
    <div class="bg-white/50">
      <div class="px-4 py-12 mx-auto max-w-7xl sm:py-16 sm:px-6 lg:px-8">
        <div class="max-w-3xl mx-auto divide-y-2 divide-white">
          <h2
            class="text-3xl font-bold tracking-tight text-center text-gray-900 sm:text-4xl"
          >
            À propos de Rouky
          </h2>
          <dl class="mt-6 space-y-6 divide-y divide-white">
            <Disclosure
              as="div"
              v-for="faq in faqs"
              :key="faq.question"
              class="pt-6"
              v-slot="{ open }"
            >
              <dt class="text-lg">
                <DisclosureButton
                  class="flex items-start justify-between w-full text-left text-gray"
                >
                  <span class="font-medium text-gray-900">{{
                    faq.question
                  }}</span>
                  <span class="flex items-center ml-6 h-7">
                    <ChevronDownIcon
                      :class="[
                        open ? '-rotate-180' : 'rotate-0',
                        'h-6 w-6 transform',
                      ]"
                      aria-hidden="true"
                    />
                  </span>
                </DisclosureButton>
              </dt>
              <DisclosurePanel as="dd" class="pr-12 mt-2">
                <p class="text-base text-gray-500">
                  {{ faq.answer }}
                </p>
              </DisclosurePanel>
            </Disclosure>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Bars3Icon, XMarkIcon } from "@heroicons/vue/24/outline";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { ChevronDownIcon } from "@heroicons/vue/24/outline";
import { RouterLink } from "vue-router"

const navigation = [
  { name: "Discussion", href: "/", current: false },
  { name: "A propos", href: "/about", current: true },
];

const faqs = [
  {
    question: "Pourquoi Rouky ?",
    answer: `Aujourd'hui, l'intelligence artificielle est partout. En décembre 2022, ChatGPT est
sorti et a eu un succès immédiat. Ce fût alors l'occasion de tester ses capacités. Rouky est né de
cette expérience. Je me suis d'abord demandé s'il pouvait m'aider à régler les problèmes de petits
coups de blues. Au fur et à mesure de mes tests, j'ai pu affiner les questions posées afin que ses
réponses soient les plus pertinentes possible. Enfin, j'ai décidé de le rendre accessible afin qu'il
puisse aider d'autres personnes que moi.`,
  },
  {
    question: "Combien ça coûte ?",
    answer: `J'ai décidé de rendre Rouky accessible gratuitement. L'API d'OpenAI a un faible coût que j'assume seul (si ça peut vous aider, ça me fait plaisir !). Cependant, si vous souhaitez me
soutenir, vous pouvez m'offrir un café en cliquant sur le bouton "Soutenir" en haut à droite. Cela
me permettra de continuer à améliorer Rouky et de lui ajouter de nouvelles fonctionnalités. Merci
d'avance !`,
  },
  {
    question: "Comment Rouky fonctionne-t-il ?",
    answer: `Rouky est un chatbot. Il utilise l'intelligence artificielle pour répondre à vos
questions. Il était historiquement basé sur le modèle GPT-3 d'OpenAI. Désormais, c'est le modèle GPT-4o-mini qu'il utilise. C'est un modèle d'apprentissage automatique
qui a été entraîné sur des milliards de paramètres. Il est capable de générer du texte de manière
automatique. Il est donc capable de répondre à vos questions de manière très précise. L'intelligence artificielle peut parfois dire des choses étranges... Si vous rencontrez un problème, n'hésitez pas à me contacter à hellofabien@pm.me ou
sur Telegram @hellofabien.`,
  },
  {
    question: "Comment Rouky est-il développé ? Est-ce sûr ?",
    answer: `Rouky est développé en JavaScript. Il est hébergé sur des serveurs OVH français. Il
ne stocke aucune donnée personnelle. Les données envoyées à OpenAI ne sont pas reliées à vous. Il
est donc possible de l'utiliser sans crainte.`,
  },
  {
    question: "Comment puis-je aider Rouky ?",
    answer: `Vous pouvez aider Rouky en me donnant des idées d'amélioration (@hellofabien sur
Telegram ou par mail à hellofabien@pm.me). Vous pouvez aussi m'aider à le promouvoir en en parlant
autour de vous et en le partageant. Enfin, vous pouvez m'offrir un café en cliquant sur le bouton
"Soutenir" en haut à droite.`,
  },
  {
    question: "Comment puis-je contacter Rouky ?",
    answer: `Vous pouvez contacter Rouky en cliquant sur le bouton "Discuter" en haut à droite.
Puisqu'il n'est pas humain, vous ne pouvez pas le contacter par téléphone ou par mail (dommage...).`,
  },
  {
    question: "Quelle est la limite d'utilisation de Rouky ?",
    answer: `Rouky était sans limite d'utilisation à ses débuts. Cependant il a rapidement été
utilisé de manière abusive. J'ai donc décidé de mettre en place une limite de demandes par heure sans exiger la création d'un compte. Cela permet de limiter l'utilisation abusive tout en
permettant à tous ceux qui en auraient besoin de l'utiliser facilement.`,
  },
];
</script>

<template>
  <!--
    This example requires updating your template:

    ```
    <html class="h-full">
    <body class="h-full">
    ```
  -->
  <div
    class="min-h-[calc(100vh-5rem)] sm:min-h-screen bg-cover"
    :style="{ backgroundImage: `url(${require('../assets/test.svg')}` }"
  >
    <Disclosure as="nav" class="bg-white border-b" v-slot="{ open }">
      <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div class="flex justify-between h-16">
          <div class="flex">
            <div class="flex items-center flex-shrink-0">
              <RouterLink
                to="/"
                class="block w-auto text-3xl cursor-pointer"
              >
                🦊
              </RouterLink>
            </div>
            <div class="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
              <router-link
                v-for="item in navigation"
                :key="item.name"
                :to="item.href"
                :class="[
                  item.current
                    ? 'border-indigo-500 text-gray-900'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium',
                ]"
                :aria-current="item.current ? 'page' : undefined"
                >{{ item.name }}</router-link
              >
            </div>
          </div>
          <div class="flex items-center -mr-2 sm:hidden">
            <a
              class="flex items-center px-2 py-2 mr-2 text-sm text-gray-600 border-gray-300 border-dashed rounded-md"
              style="border-width: 1px"
              href="https://www.buymeacoffee.com/hellofabien"
              target="_blank"
              ><img class="w-5 h-5" :src="require('../assets/gift.svg')" />
              <span class="ml-2">Soutenir</span></a
            >
            <!-- Mobile menu button -->
            <DisclosureButton
              class="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-md hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <span class="sr-only">Open main menu</span>
              <Bars3Icon
                v-if="!open"
                class="block w-6 h-6"
                aria-hidden="true"
              />
              <XMarkIcon v-else class="block w-6 h-6" aria-hidden="true" />
            </DisclosureButton>
          </div>
          <div class="items-center hidden sm:flex">
            <a
              class="flex items-center h-10 px-3 mr-2 text-sm text-gray-600 border-gray-300 border-dashed rounded-md"
              style="border-width: 1px"
              href="https://www.buymeacoffee.com/hellofabien"
              target="_blank"
              ><img class="w-5 h-5" :src="require('../assets/gift.svg')" />
              <span class="ml-2">Soutenir</span></a
            >
          </div>
        </div>
      </div>

      <DisclosurePanel class="sm:hidden">
        <div class="pt-2 pb-3 space-y-1">
          <router-link
            v-for="item in navigation"
            :key="item.name"
            :to="item.href"
            :class="[
              item.current
                ? 'bg-indigo-50 border-indigo-500 text-indigo-700'
                : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800',
              'block px-3 py-2 text-base font-medium border-b',
            ]"
            :aria-current="item.current ? 'page' : undefined"
            >{{ item.name }}</router-link
          >
        </div>
      </DisclosurePanel>
    </Disclosure>

    <div class="pt-4 sm:pt-8">
      <header>
        <div class="flex max-w-xl px-4 mx-auto">
          <img
            class="flex object-cover w-16 h-16 mr-4 rounded-full"
            :src="require('../assets/fox.png')"
            alt=""
          />
          <h1
            class="pb-4 text-2xl font-bold leading-tight tracking-tight text-white sm:pb-0"
            style="text-shadow: 0 0 5px rgba(0, 0, 0, 0.5)"
          >
            Pas le moral ? <br />
            Ma mission est de te le remonter !
          </h1>
        </div>
      </header>
      <main class="pb-3 sm:m-4 sm:pb-0">
        <div
          class="max-w-xl mx-3 overflow-hidden bg-white rounded-lg sm:mx-auto"
        >
          <Feed></Feed>
        </div>
      </main>
    </div>
  </div>
</template>

<script setup>
import Feed from "./Feed.vue";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { Bars3Icon, XMarkIcon } from "@heroicons/vue/24/outline";
import { RouterLink } from "vue-router"

const navigation = [
  { name: "Discussion", href: "/", current: true },
  { name: "A propos", href: "/about", current: false },
];
</script>

<template>
  <div class="flex items-end m-2 space-x-2 sm:m-4">
    <div class="flex-shrink-0">
      <img
        class="inline-block object-cover rounded-full h-9 w-9"
        :src="require('../assets/avatar.png')"
        alt=""
      />
    </div>
    <div class="relative flex-1 min-w-0">
      <div
        class="overflow-hidden border border-gray-200 rounded-lg shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500"
      >
        <label for="comment" class="sr-only">Écris ton message...</label>
        <textarea
          @keydown.enter.prevent="sendMessage"
          rows="2"
          v-model="message"
          name="comment"
          id="comment"
          class="block w-full py-3 border-0 resize-none focus:ring-0 sm:text-sm"
          placeholder="Écris ton message..."
        ></textarea>

        <!-- Spacer element to match the height of the toolbar -->
        <div class="py-2" aria-hidden="true">
          <!-- Matches height of button in toolbar (1px border + 36px content height) -->
          <div class="py-px">
            <div class="h-9" />
          </div>
        </div>
      </div>

      <div
        class="absolute inset-x-0 bottom-0 flex justify-between px-2 py-2 sm:py-3 sm:px-3"
      >
        <div class="flex items-center space-x-5">
          <div class="flex items-center"></div>
        </div>
        <div class="flex-shrink-0">
          <button
            @click="sendMessage"
            class="inline-flex items-center px-3 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <PaperAirplaneIcon class="w-4 h-4 mr-2" aria-hidden="true" />
            Envoyer
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { PaperAirplaneIcon } from "@heroicons/vue/24/outline";
import { defineEmits } from "vue";
const emit = defineEmits(["newMessage"]);

let message = "";

function sendMessage() {
  emit("newMessage", message);
  message = "";
  let textarea = document.getElementById("comment");
  textarea.value = "";
}
</script>

<!--
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  const colors = require('tailwindcss/colors')
  
  module.exports = {
    // ...
    theme: {
      extend: {
        colors: {
          rose: colors.rose,
        },
      },
    },
  }
  ```
-->
<template>
	<div class="flex flex-col justify-between space-around min-h-[25rem]">
		<ul role="list flow-root">
			<li
				v-for="(activityItem, activityItemIdx) in activity"
				:key="activityItem.id"
				:class="
					activityItem.type === 'error'
						? 'bg-amber-50'
						: activityItemIdx % 2 !== 0
						? 'bg-gray-50'
						: ''
				"
				class="px-2 sm:px-4"
			>
				<div class="relative py-4">
					<!-- <span
            v-if="activityItemIdx !== activity.length - 1"
            class="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
            aria-hidden="true"
          /> -->
					<div class="relative flex items-start space-x-3">
						<template v-if="activityItem.type === 'comment'">
							<div class="relative">
								<img
									class="flex items-center justify-center object-cover w-10 h-10 bg-gray-400 rounded-full ring-8 ring-white"
									:class="activityItemIdx % 2 !== 0 ? 'ring-gray-50' : ''"
									:src="
										activityItem.name === 'Rouky'
											? require('../assets/fox.png')
											: activityItem.imageUrl
									"
									alt=""
								/>
							</div>
							<div class="flex-1 min-w-0">
								<div>
									<div class="text-sm">
										<p class="font-medium text-gray-900">
											{{ activityItem.name }}
										</p>
									</div>
								</div>
								<div class="mt-2 text-sm text-gray-700">
									<p>{{ activityItem.content }}</p>
								</div>
							</div>
						</template>
						<template v-if="activityItem.type === 'error'">
							<div class="relative">
								<img
									class="flex items-center justify-center object-cover w-10 h-10 bg-gray-400 rounded-full ring-8 ring-white"
									:class="
										activityItem.type === 'error'
											? 'ring-amber-50'
											: activityItemIdx % 2 !== 0
											? 'ring-gray-50'
											: ''
									"
									:src="activityItem.imageUrl"
									alt=""
								/>
							</div>
							<div class="flex-1 min-w-0">
								<div>
									<div class="text-sm">
										<p class="font-medium text-gray-900">
											{{ activityItem.name }}
										</p>
									</div>
								</div>
								<div class="mt-2 text-sm text-gray-700">
									<p>{{ activityItem.content }}</p>
								</div>
							</div>
						</template>
					</div>
				</div>
			</li>
			<li class="px-2 sm:px-6" v-if="isWriting === true">
				<div class="relative py-4">
					<div class="relative flex items-start space-x-3">
						<div class="opacity-70">
							<div class="relative px-1">
								<div
									class="flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full ring-4 ring-white"
								>
									<img
										class="flex items-center justify-center object-cover w-8 h-8 bg-gray-400 rounded-full ring-4 ring-white"
										:src="require('../assets/fox.png')"
										alt=""
									/>
								</div>
							</div>
						</div>
						<div class="flex-1 min-w-0">
							<div class="mt-1.5 text-sm text-gray-400">
								<p>Rouky est en train d'écrire...</p>
							</div>
						</div>
					</div>
				</div>
			</li>
		</ul>
		<MessageInput @newMessage="(msg) => newMessage(msg)"></MessageInput>
	</div>
</template>

<script setup>
import MessageInput from './MessageInput.vue';
import { ref } from 'vue';
import axios from 'axios';

let isWriting = ref(false);

let activity = ref([
	{
		id: 0,
		type: 'comment',
		role: 'assistant',
		name: 'Rouky',
		content:
			'Salut ! Je suis Rouky, un petit compagnon destiné à te remonter le moral en cas de petit coup de blues. Tu peux me poser des questions, me raconter tes joies et tes peines, je suis là pour toi !',
	},
]);

function buildHistory() {
	// make sure conversation history does not exceed 50 messages
	const last50Messages = activity.value.slice(-50);
	let history = last50Messages.map((item) => {
		return {
			role: item.role,
			content: item.content,
		};
	});
	return history;
}

async function newMessage(msg) {
	activity.value.push({
		id: activity.value.length + 1,
		role: 'user',
		type: 'comment',
		name: 'Moi',
		imageUrl: require('../assets/avatar.png'),
		content: msg,
	});
	window.scrollTo(0, document.body.scrollHeight);
	isWriting.value = true;
  const history = buildHistory();

	const response = await axios
		.post(`${process.env.VUE_APP_BACKEND_URL}/chat/`, {
			messages: history,
		})
		.catch((err) => {
			if (err.response?.status === 429) {
				isWriting.value = false;
				activity.value.push({
					id: activity.value.length + 1,
					type: 'error',
					role: 'assistant',
					name: 'Rouky',
					content: `Il semble que tu aimes beaucoup Rouky 😇 N'hésite pas à faire un don si tu peux (et veux) pour l'aider à rester en ligne. Merci de patienter 1 heure avant de pouvoir l'utiliser de nouveau 👍`,
				});
				return;
			}
			isWriting.value = false;
			activity.value.push({
				id: activity.value.length + 1,
				type: 'error',
				role: 'assistant',
				name: 'Rouky',
				content: '⚠️ Une erreur est survenue ou vous avez dépassé la limite de messages dans l\'heure, veuillez réessayer plus tard.',
			});
		});
	
		isWriting.value = false;
		activity.value.push({
			id: activity.value.length + 1,
			type: 'comment',
			role: 'assistant',
			name: 'Rouky',
			content: response.data.response,
		});
}
</script>
